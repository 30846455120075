import axios2 from '@/axios2';
import { CheckupType } from "@/code/models/assistent/CheckupType";
import { ProductInfo } from '@/code/models/assistent/ProductInfo';

export class AssistentService {

  constructor() {
  }

  reviewAccount(idAccount: number) : Promise<Array<CheckupType>> {
    return new Promise((resolve, reject) => {
      // axios2.get('dashboards/v1')
      // .then(
      //   (response: any) => {
          let result = new Array<CheckupType>();
      //     if (response.data && response.data.data) {
      //       response.data.data.forEach(el => {
      //         result.push(new CheckupType(el));
      //       });
      //     }

      // ----------FAKE DATA---------------------
      result.push(new CheckupType({ type: 'fail', domain: 'user', title: 'Teste de revisão', error: 'Faltou sincronizar o usuário na base da Linx' }));
      result.push(new CheckupType({ type: 'warning', domain: 'account', title: 'Teste de conta', error: 'A conta não possui nenhum usuário vendedor' }));
      result.push(new CheckupType({ type: 'ok', domain: 'group', title: 'Teste de grupo', message: 'Os dados do grupo estão todos corretos' }));
      result.push(new CheckupType({ type: 'fail', domain: 'linx', title: 'Teste de integração com a Linx', error: 'Não foi possível sincronizar com o servidor da Linx' }));
      result.push(new CheckupType({ type: 'ok', domain: 'payments', title: 'Teste de formas de pagamento', message: 'A configuração das formas de pagamento está correta!' }));


          resolve(result);
      //   }
      // ),
      // (error: any) => {
      //   reject(error);
      // }

    })
  };

  getProductInfo(idAccount: number): Promise<Array<ProductInfo>> {
    return new Promise((resolve, reject) => {
      axios2.get(`/products/${idAccount}/count`)
      .then(
        (response: any) => {
          let result = new Array<ProductInfo>();
          if (response.data && response.data.data) {
            response.data.data.forEach(el => {
              result.push(new ProductInfo(el));
            });
          }

          resolve(result);
        }
      ),
      (error: any) => {
        reject(error);
      }

    });
  };

  sendByEmail(email: String): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      resolve(true);
    })
  };

  startUpdate(idAccount: number, refresh=false): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      axios2.get(`products/sync-all?ids=${idAccount}&refresh=${refresh ? 1 : 0}`)
      .then(
        (response: any) => {
          if (response.data && response.data.status === 'success') {
            resolve(true);
          }
        }
      ),
      (error: any) => {
        reject(error);
      }

    });
  }

}
