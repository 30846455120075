export class CheckupType {

  constructor(attributes: any) {
    this.type = attributes.type;
    this.domain = attributes.domain;
    this.title = attributes.title;
    this.message = attributes.message;
    this.error = attributes.error;
  }

  type: 'fail' | 'warning' | 'ok';
  domain: 'user' | 'account' | 'group' | 'linx' | 'payments';
  title: string;
  message?: string;
  error?: string;

}