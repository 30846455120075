export class ProductInfo {

    public text!: String;
    public type!: String;
    public percentPriceSynchronized!: number;
    public quantityDisabled!: number;
    public quantityEnable!: number;
    public quantityTotal!: number;
    public quantityWithPrice!: number;

    constructor(attributes: any) {
        Object.assign(this, attributes);
    }
}
