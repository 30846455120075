































































import { AssistentService } from '@/code/services/assistent/assistentService';
import { CheckupType } from '@/code/models/assistent/CheckupType';
import Vue from 'vue';
import Swal from 'sweetalert2';
import { EventBus } from '@/code/util/eventBus';
import { Loading } from '@/code/util/swalUtil';

export default Vue.extend({

  data() {
    return {
      checkupTypes: [] as Array<CheckupType>,
      idAccount: 0
    }
  },

  mounted(): void {
    try {
      this.idAccount = parseInt(this.$route.params.id);
    } catch (ex: any) {
      this.idAccount = 0;
    }
    const that = this;
    if (this.idAccount && this.idAccount > 0) {
      this.doReview();
    } else {
      Swal.fire({
        title: 'Parâmetro inválido',
        text: 'A loja informada é invalida!',
        icon: 'error'
      })
      .then(
        (result) => {
          that.$router.replace({ name: 'assistentAction' });
        }
      )
    }
  },

  methods: {
    doReview: async function() {
      try {
        Loading.fire("Fazendo a revisão dos dados da conta!");
        const service = new AssistentService();
        this.checkupTypes = await service.reviewAccount(this.idAccount);
        Swal.close();
      } catch {
        //do nothing
      }
    },
    getColor: function(checkupType: CheckupType): String {
      switch (checkupType.type) {
        case 'fail':
          return 'red lighten-2';
        case 'warning':
          return 'amber lighten-1';
        case 'ok':
          return 'green lighten-1';
        default:
          return '';
      }
    },
    getIcon: function(checkupType: CheckupType): String {
      switch (checkupType.type) {
        case 'fail':
          return 'fas fa-times';
        case 'warning':
          return 'fas fa-exclamation-triangle';
        case 'ok':
          return 'fas fa-check';
        default:
          return '';
      }
    },
    sendByEmail: function() {
      Swal.fire({
        title: 'Informe o email para enviar as informações',
        input: 'email',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Enviar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: async (email) => {
          //validar o email informado
          const service = new AssistentService();
          await service.sendByEmail(email);
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },

    previousTab: function() {
      EventBus.$emit('previousAssistentRoute');
    },

    finish: function() {
      EventBus.$emit('nextAssistentRoute');
    }
  }

})
